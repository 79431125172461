<template>
  <v-dialog :value="dialog" width="1200px" persistent>
    <v-card class="px-2 pb-2 pt-4">
      <v-card-text class="px-3 pt-4">
        <v-row align="center" no-gutters>
          <v-col cols="4">
            <h2>Configuração do Abono Temporário</h2>
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-col cols="3">
                <date-picker label="Data de Início" :allowed-dates="allowedDates" :date.sync="date_init" outline></date-picker>
              </v-col>
              <v-col cols="3">
                <date-picker label="Data de Fim" :allowed-dates="blockLastMonth" :date.sync="date_final" outline></date-picker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table v-if="productsList.length" key="index" :footer-props="{ itemsPerPageOptions: [5, 10] }" :headers="headers" :items="products">
          <template v-slot:[`item.product_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.product_type).name }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.slots`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.slots | localeString(0) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.total_mrr`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrencyDefault(item.total_mrr) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ formatDate(item.created_at) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.quantity_paid`]="{ item }">
            <v-row>
              <v-col>
                <field-toggle v-model="productsList[getIndex(item)].quantity_paid" @input="value => quantityPaidChanged(value, getIndex(item))"></field-toggle>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.amount_paid`]="{ item }">
            <v-row>
              <v-col>
                <field-toggle
                  v-model="productsList[getIndex(item)].amount_paid"
                  money
                  :precision-money="getPrecisionMoney(item)"
                  @input="value => amountPaidChanged(value, getIndex(item))"
                ></field-toggle>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.total_amount_paid`]="{ item, index }">
            <v-row>
              <v-col>
                <field-toggle
                  v-model="productsList[getIndex(item)].total_amount_paid"
                  money
                  :precision-money="getPrecisionMoney(item)"
                  @input="value => totalAmountPaidChanged(value, getIndex(item))"
                ></field-toggle>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.unitary_value`]="{ item }">
            <v-row>
              <v-col>
                {{ calculateUnitaryValue(item.total_mrr, item.slots, item.product_type) }}
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <v-divider class="mx-2" />
      </v-card-text>
      <v-card-actions class="my-2">
        <v-spacer />
        <mf-action-buttons
          class="mr-1"
          :primary-button="{
            text: 'Atualizar valores',
            action: saveTemporaryChurn,
            isVisible: true,
            isDisabled: isQuantityValid
          }"
          :extra-button="{
            text: 'Cancelar',
            action: close,
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep, round } from 'lodash'
import { DateTime } from 'luxon'
import { allowedDateCrmActions } from '../../../helpers/crm'
import { parseCurrency, productsInformation } from '@/mixin'
export default {
  components: {
    DatePicker: () => import('@/components/calendar/DatePicker.vue'),
    FieldToggle: () => import('@/components/atomic-components/atoms/FieldToggle.vue')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    products: {
      type: Array,
      default: () => []
    },
    clientId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      productsList: [],
      date_init: DateTime.now().toISODate(),
      date_final: DateTime.now().toISODate(),
      disabled: false,
      contract_link: '',
      observation: ''
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Produto',
          value: 'product_type',
          align: 'center'
        },
        {
          text: 'Total MRR',
          value: 'total_mrr',
          align: 'center'
        },
        {
          text: 'Total disponível',
          value: 'slots',
          align: 'center'
        },
        {
          text: 'Valor unitário',
          value: 'unitary_value',
          align: 'center'
        },
        {
          text: 'Criado em',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Quantidade a ser abonada',
          value: 'quantity_paid',
          align: 'center'
        },
        {
          text: 'Valor unitário a ser abonado',
          value: 'amount_paid',
          align: 'center'
        },
        {
          text: 'Valor total a ser abonado',
          value: 'total_amount_paid',
          align: 'center'
        }
      ]
    },
    isQuantityValid() {
      const valid = this.products.filter((product, index) => {
        return (
          product?.quantity <= this.productsList?.[index]?.quantity_paid ||
          product?.total_mrr < this.productsList?.[index]?.amount_paid ||
          this.productsList?.[index]?.quantity_paid < 0 ||
          this.productsList?.[index]?.amount_paid < 0 ||
          this.productsList?.[index]?.quantity_paid === 0 ||
          this.productsList?.[index]?.amount_paid === 0 ||
          this.disabled
        )
      })
      return !!valid.length
    }
  },
  mounted() {
    if (this.products.length) {
      let products_copy = cloneDeep(this.products)
      this.productsList = products_copy.map(item => {
        return {
          ...item,
          quantity_paid: 0,
          amount_paid: 0,
          total_amount_paid: 0
        }
      })
    }
  },
  methods: {
    totalAmountPaidChanged(value, index) {
      this.productsList[index].amount_paid = round(value / this.productsList[index].quantity_paid, this.getPrecisionMoney(this.productsList[index]))
    },

    amountPaidChanged(value, index) {
      this.productsList[index].total_amount_paid = round(value * this.productsList[index].quantity_paid, this.getPrecisionMoney(this.productsList[index]))
    },

    quantityPaidChanged(value, index) {
      this.productsList[index].total_amount_paid = round(value * this.productsList[index].amount_paid, this.getPrecisionMoney(this.productsList[index]))
    },

    getPrecisionMoney(item) {
      return ['sms', 'email'].includes(item.product_type) ? 4 : 2
    },
    allowedDates(val) {
      return allowedDateCrmActions(val)
    },
    blockLastMonth(val) {
      const now = DateTime.now().toFormat('yyyy-MM')
      const date_val = DateTime.fromFormat(val, 'yyyy-MM-dd').toFormat('yyyy-MM')
      return now <= date_val
    },
    calculateUnitaryValue(total_mrr, slots, type) {
      let result = total_mrr / slots
      if (result === Infinity || isNaN(result)) {
        return 0
      }
      return type === 'sms' ? this.parseCurrencySms(result) : this.parseCurrencyDefault(result)
    },
    formatDate(date) {
      return this.$moment(date)
        .add(3, 'h')
        .format('DD/MM/YYYY - HH:mm:ss')
    },
    close() {
      this.$emit('update:dialog', false)
    },
    validateItems(productsList) {
      const validation = productsList.map(product => {
        const total_mrr = product.total_mrr
        let newValue = product.quantity_paid * product.amount_paid
        newValue = parseFloat(newValue.toFixed(2))
        return total_mrr < newValue
      })

      return !validation.includes(true)
    },
    async saveTemporaryChurn() {
      const isValid = this.validateItems(this.productsList)
      if (!isValid) return this.$snackbar({ message: 'Falha validar venda, favor verificar os valores inseridos', snackbarColor: '#F44336' })

      const variables = {
        client_id: this.clientId,
        sale: {
          type: 'temporary_churn',
          temporary_churn_period: {
            start_date: this.date_init,
            end_date: this.date_final
          },
          items: this.productsList.map(item => {
            return {
              sale_id: item.sale_id,
              index: item.index,
              name: item.name,
              type: item.product_type,
              quantity: item.quantity_paid,
              unit_value: item.amount_paid,
              total_value: item.quantity_paid * item.amount_paid
            }
          })
        }
      }
      this.$emit('click', variables)
    },
    getIndex(val) {
      return this.products.indexOf(val)
    }
  }
}
</script>
